.small-icon {
    padding-top: 10px;
    height: 25px;
    color: #6495ed;
}

.large-icon {
    margin:auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    height: 45px;
    width: 45px;
    opacity: 60%;
    padding-top: 10px;
    color: #6495ed;
}

/*  original styling in svg tag */
/* style={margin:'auto',marginLeft:'10px',marginRight:'10px', height:'45px',width:'45px',opacity:'60%'} */