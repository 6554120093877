
.about-container {
    min-height: 100vh;
    width: 100vw;
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0)),url(/src/assets/white-backround1.jpg);
    background-size: cover; 
    background-position: center bottom;
    background-attachment: fixed;
    display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.about-container section {
    margin: 10px;
    display: flex;
    justify-content: center;
}

.about-container div {
    min-height: 300px;
}

::-webkit-scrollbar {
    width: 20px;
  }

    @media (max-width: 850px){
    .about-container section {
        flex-direction: column;
    }
    .about-container div {
        margin: auto;
        margin-bottom: 5px;
    }
}

@media (max-width:325px) {
    .about-container div {
        width: 100%;
    }
}