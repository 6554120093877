
.notice-container{
    position: absolute;
    width: 120px;
    height: 30px;
    background: linear-gradient(0turn,#5077be,#6495ed);
    border-radius: 5px;
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear;
    transform: translateX(-78px) rotate(0);
    box-shadow: 0 4px 3px 0  rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 4s, opacity 4s linear;
}

.notice-container::before {
    content: "";
    position: absolute;
    left: -92px;
    top: -105%;
    width: 0;
    height: 0;
    border-top: 1px solid transparent;
    border-right: solid 20px #6495ed;
    border-bottom: 33px solid transparent;
    border-left: 10px solid transparent;
    transform: translateX(161px) rotate(90deg);
  }

.notice-container-outer::before {
    position: absolute;
    left: 24px;
    bottom: 0;
    height: 1px;
    width: 18%;
    background-color: #6495ed;
    content: "";
    transform: translateY(-71px) rotate(38deg);
    transform-origin: left bottom;
}

.notice-container-outer::after {
    position: absolute;
    left: 11%;
    bottom: 1px;
    height: 1px;
    width:12%;
    background-color: #6495ed;
    content: "";
    transform: translateY(-69px) rotate(116deg);
    
    transform-origin: left bottom;
}

  .about-links {
    text-transform: none;
    top: 120px;
  }