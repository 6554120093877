.friend-container {
    width: 650px;
    height: 800px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
	flex-wrap: wrap;
    justify-content: flex-start;
    border-radius: 2%;
    overflow-y:auto;
}

@media (max-width:700px){
    .friend-container {
        /* width: 350px; */
        align-items: center;
    }
}