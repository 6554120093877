.explore-container {
    /* position: relative; */
    min-height: 70vh;
    /* max-height: 120vh; */
    /* min-width: 300px; */
    max-width: 100vw;
    /* background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0)),url(white-backround1.jpg); */
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0)),url(/src/assets/white-backround1.jpg);
    background-size: cover; 
    background-position: center bottom;
    display: flex;
	flex-direction: row;
    /* flex-direction: column; */
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: stretch;
	align-content: space-around;
    overflow-x: scroll;
}

.search-menu {
    position: absolute;
    position: fixed;
    top: 70px;
    /* left: 70vw; */
    left: 30px;
    width: 160px;
    height: 50px;
    border-radius: 8px;
    background: linear-gradient(0turn,#5077be,#6495ed);
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
    z-index: 1;
}

.search-menu-closed {
    position: absolute;
    position: fixed;
    opacity: .7;
    top: 70px;
    /* left: 70vw; */
    left: 30px;
    width: 60px;
    height: 50px;
    background: linear-gradient(0turn,#5077be,#6495ed);
    border-radius: 8px;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
    z-index: 1;
}

.show-bar { display: inline; }
.dont-show-bar {
    display: none;
}
.slide-over {
    top: 130px;
}

h4 {
    color: #555;
    margin: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    /* color: #fff; */
    color: #555;
    /* background-color: #fff; */
    opacity: .5; 
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    /* color: #fff; */
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    /* color: #fff; */
  }

  @media (max-width:825px) {
      .search-menu-closed {
        -webkit-transform: scale(.50);
        -ms-transform: scale(.50);
        transform: scale(.50);
        opacity: 1;
        /* top: 100px; */
        left: 0px;
      }
      .search-menu {
          left: 0px;
          /* top: 100px; */
      }
      .slide-over {
          top: 100px;
      }
  }

/* @media (max-width: 350px) {
    .explore-container {
        max-width: 100vw;
    }
} */

@media (max-width:300px) {
    .explore-container {
        min-width: 300px;
    }
}