$size: 15px;

.crown {
	position: absolute;
	top: 0px;
	left: 50%;
	transform: translate(-50%,-50%);
    width: 40px;
    height: 40px;
	width: $size+5;
	height: $size/1.1;
	overflow: hidden;
	z-index: 10;
}
	.point {
		position: absolute;
		background-color: gold;
		transform: translate(-50%,50%) rotate(45deg) skew(30deg,30deg);
		
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: $size/5;
			height: $size/5;
			border-radius: $size/5;
			background-color: gold;
			transform: translate(-50%,-50%) skew(-30deg,-30deg);
		}
		
		&:first-child,
		&:last-child {
			top: $size/2;
			width: $size;
			height: $size;
		}
		
		&:nth-child(2),
		&:nth-child(4) {
			top: $size/3;
			width: $size;
			height: $size;
		}
		
		&:nth-child(3) {
			top: $size/4;
			width: $size;
			height: $size;
		}
		
		&:first-child {
			left: 0;
		}
		
		&:nth-child(2) {
			left: 25%;
		}
		
		&:nth-child(3) {
			left: 50%;
		}
		
		&:nth-child(4) {
			left: 75%;
		}
		
		&:last-child {
			left: 100%;
		}
	}
