.create-message-container {
    /* position: absolute; */
    position: fixed;
    top: 10%;
    height: 300px;
    width: 500px;
    background-color: #fff;
    z-index: 1000;
    display: flex;
	flex-direction: column;
	/* flex-wrap: nowrap; */
	/* justify-content: flex-start; */
    justify-content: space-between;
	align-items: center;
	/* align-content: stretch; */
    border-radius: 5px;
    box-shadow:10px 5px 60px 10px rgba(36, 36, 36, 1);
    z-index: 1;
    overflow: hidden;
}

.create-message-input {
    position: relative;
    /* position: absolute; */
    width: 300px;
    height: 60px;
    /* top: 0px; */
    /* background-color: yellow; */
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
    align-items: center;
    /* right: 40px; */
    top: 60px;
    margin-bottom: 10px;
    /* margin-bottom: 1px; */
}

.thread-layout {
    /* width: 100px; */
    /* background-color: yellow; */
    position: relative;
    height: 180px;
    width: 100%;
    /* background-color: yellow; */
    display: flex;
	flex-direction: column;
	/* flex-wrap: nowrap; */
	/* justify-content: flex-start; */
    /* justify-content: space-between; */
	align-items: center;

	/* align-content: stretch; */
	/* align-items: stretch; */
	/* align-content: stretch; */
    overflow: scroll;
}

/* .view-user-messages {

} */

@media (max-width:825px) {
    .create-message-container {
        width: 400px;
    }
}

@media (max-width:620px) {
    /* .create-message-container {
        width: 300px;
    } */
    .new-message-text-input {
        width: 250px;
    }
}

@media (max-width:420px) {
    .create-message-container {
        width: 300px;
    }
}

@media (max-height:430px) {
    .create-message-input {
        top: 40px;
    }

}