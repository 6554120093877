
.message-board {
    position: absolute;
    position: fixed;
    width: 800px;
    height: 550px;
    left: 20%;
    top: 30px;
    display: flex;
	flex-direction: row;
    /* flex-direction: column; */
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	align-content: flex-start;
    /* background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0)),url(white-backround1.jpg); */
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0)),url(/src/assets/white-backround1.jpg);
    /* background: rgba(231, 231, 231, 0.322); */
    box-shadow:10px 5px 60px 10px rgba(36, 36, 36, 1);
    z-index: 2;
    transition: all 100ms;
    border-radius: 10px;
    overflow: hidden;
    /* overflow: scroll; */
}

.message-board-title {
    color: #fff;
    position: absolute;
    text-transform: none;
    top: -1px;
    height: 60px;
    width: 815px;
    background: linear-gradient(0turn,#5077be,#6495ed);
    margin: none;
    transition: all 500ms;
    z-index: 1000000;
    border-radius: 10px;
}

.close-message-box {
    position: absolute;
    top: 1px;
    right: 7px;
}
.toggle-contacts {
    display: none;
    /* position: absolute; */
    /* top: 68px; */
    /* left: 20px; */
    /* color: #5077be; */
}

.new-message {
    /* position: absolute; */
    position: fixed;
    /* color: #e67e22; */
    color: red;
    left: 55px;
    top: 100px;
    height: 20px;
    width: 50px;
    font-weight: bold;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;

    box-shadow: 0 4px 3px 0  rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);

    border-radius: 10px;
    background: rgba(165, 165, 165, 0.116);
    backdrop-filter: blur(120px);
    z-index: 1000;
}

.message-board-closed {
    /* position: fixed; */
    /* left: 100px; */
    left: 30px;
    top: 70px;
    z-index: 10000;
    /* position: absolute; */
    position: fixed;
    opacity: .7;
    width: 60px;
    height: 50px;
    background: linear-gradient(0turn,#5077be,#6495ed);
    border-radius: 8px;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
    z-index: 10000;
    transition: all 500ms;
    z-index: 2;
}

/* .board-flex {
    display: flex;
    flex-direction: column;

} */

.dash {
    margin-top: 70px;
    width: 210px;
    min-height: 90%;
    margin-right: 5px;
    z-index: 100000;
    overflow: scroll;
}
.mobile-dash {
    display: none;
}

.board {
    position: relative;
    width: 550px;
    height: 420px;
    /* height: 550px; */
    display: flex;
	flex-direction: column-reverse;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	/* align-content: stretch; */
    overflow: scroll;
    /* background-color: #fff; */
    /* box-shadow: 0 2.5rem 2rem -2rem hsl(200 50% 20% / 40%); */
    margin-top: 75px;
    /* z-index: 10000000000000; */
}

/* .column-flex {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 500px;
    background-color: yellow;
} */

.text-input-container {
    position: absolute;
    /* bottom: 65px; */
    /* height: 130px; */
    /* height: 80px; */
    /* width: 370px; */
    /* padding-top: 5px; */
    /* background-color: #fff; */
    border-radius: 4px;
    /* box-shadow: 0 4px 3px 0  rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2); */
    bottom: 40px;
    left: 350px;
    z-index: 200000000;
}
.input-background {
    position: relative;

    /* background-color: yellow; */
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    right: 40px;
    top: 20px;
    margin-bottom: 1px;
}
/* .input-button {
    width: 25px;
} */

.user-message-photo {
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0px;
    padding: 60px;
}
.my-message-photo {
    box-shadow: 0 4px 3px 0  rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0px;
    opacity: .9;
}
.user-over-flow,
.over-flow {
    position: relative;
    width: 520px;
    /* min-height: 40px; */
    min-height: 50px;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	/* align-items: center; */
    align-items: flex-end;
	align-content: flex-start;
    /* margin-top: 10px; */
    /* padding-top: 10px; */
    /* background-color: yellow; */
}
.user-over-flow { 
    flex-direction: row-reverse;
    /* background-color: pink; */
    /* align-items: flex-start; */
}

/* -------------- message box ---------------------- */

.time-stamp {
    position: absolute;
    /* position:static; */
    top: -5px;
    left: 200px;
    color: #555;
    /* margin-top: 10px; */
    opacity: .5;
}

.user-message-container,
.my-message-container{
    float: right;
    width: 420px;
    min-height: 10px;
    margin: 5px;
    margin-top:10px;
    /* padding: 3px; */
    padding: 12px;
    background-color: #6495ed;
    color: #fff;
    /* border-radius: 5px; */
    /* border-radius: 20px; */
    border-radius: 20px 20px 0px;
    visibility: visible;
    /* opacity: .9; */
    /* transition: opacity 2s linear; */
    box-shadow: inset 0 0 1px #000000;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
    /* align-items: flex-start; */
    
	align-content: stretch;
    /* font-weight: 600; */
    font-weight: 400;
    /* font-weight: 10px; */
    font-size: smaller;
    text-align: left;
}

.user-message-container{
    float: left;
    width: 420px;
    min-height: 60px;
    /* padding: 3px; */
    margin:10px;
    background: rgba(231, 231, 231, 0.322);
    background-color: #fff;
    color: #555;
    /* border-radius: 0px 20px 20px; */
    border-radius: 20px 20px 20px 0px;
    /* border-radius: 5px; */
    visibility: visible;
    /* opacity: .9; */
}

.user-time-format,
.my-time-format {
    /* float: left; */
    /* margin: none; */
    position: absolute;
    width: 50px;
    color: #fff;
    /* margin-left: 30px; */
    font-size: xx-small;
    right: 10%;
}
.user-time-format {
    color: #555;
    left: 10%;
}

@media (max-height:590px){
    .message-board {
        height: 400px;
    }
    .board {
        /* height: 250px; */
        height: 270px;
    }

    /* .dash {
        height: 250px;
    } */
}

@media (max-width:1040px) {
    .message-board {
        left: 10%;
        /* overflow: scroll; */
    }

}
@media (max-width:910px) {
    .message-board {
        left: 10px;
    }
    
}
@media(max-width:825px) {
    .message-board {
        width: 400px;
        flex-direction: row;
    }

    .input-background {
        /* right: 225px; */
        right: 280px;
        width: 230px;
    }
    .dash {
        display: none;
    }
    .mobile-dash {
        position: absolute;
        top: 105px;
        left: 20px;
        width: 245px;
        max-height: 300px;
        background: rgba(165, 165, 165, 0.116);
        backdrop-filter: blur(12px);
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        z-index: 100000000000000000;
        overflow: scroll;
    }
    .mobile-dash-closed {
        top: 100px;
        min-height: 0px;
        width: 0px;
        transition: all 100ms;
        
    }
    .toggle-contacts {
        display: inline;
        position: absolute;
        top: 68px;
        left: 20px;
        border-radius: 4px;
        /* color: #5077be; */
        color: #3c598e;
        transition: transform .2s;
        background: rgba(165, 165, 165, 0.116);
        backdrop-filter: blur(200px);
    }
    .toggle-contacts-rotated {
        transform: rotate(180deg); /* Equal to rotateZ(45deg) */
    }

    /* .message-board */
    /* .board {
        width: 300px;
    } */
    .my-message-container,
    .user-message-container{
        /* width: 220px; */
        width: 320px;
    }
    .text-input-container {
        width: 300px;
    }
    .message-board-title {
        width: 415px;
    }
    .message-board-closed {
        -webkit-transform: scale(.50);
        -ms-transform: scale(.50);
        transform: scale(.50);
        opacity: 1;
        left: 0px;
    }
    .my-message-photo {
        height: 30px;
        width: 30px;
    }
}

@media (max-width:420px) {
    .message-board {
        width: 300px;
    }
    .message-board-title {
        width: 315px;
    }
    .my-message-container,
    .user-message-container {
        width: 220px;
        /* font-size: xx-small; */
        /* font-weight: 600; */
    }

    .input-background {
        /* position: relative; */
    
        /* background-color: wheat; */
        /* display: flex; */
        /* flex-direction: column; */
        /* flex-wrap: nowrap; */
        /* justify-content: center; */
        right: 325px;
        top: 20px;
        /* z-index: 10000000000000000000000000000000; */
    }
    /* .text-input-container {
        width: 230px;
        height: 120px;
        padding-top: 0px;
        padding-bottom: 5px;
        background-color: yellow;
        z-index: 10000000000000;
    } */
    /* .my-message-photo {
        width: 20px;
        height: 20px;
        opacity: 1;
    } */
}

@media (max-height:430px) {
    .message-board-title {
        height: 40px;
    }
    .message-board {
        top:5px;
        /* height: 90vh; */
        height: 75vh;
        /* height: 90%; */
    }
    .board {
        height: 90%;
        margin-top: 0px;
        margin-bottom: auto;
    }
    .input-background {
        top: 40px;
    }
    .toggle-contacts,
    .toggle-contacts-rotated {
        top: 40px;
    }
    .mobile-dash {
        top: 40px;
        left: 50px;
    }
    .message-board-title {
        background: rgba(0,0,0,0.2);
    }
    .hide-message-h2 {
        display: none;
    }
}