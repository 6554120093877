
.signup-menu {
    border-radius: 2vw;
    margin: auto;
    margin-top: 2vh;
    padding-bottom: 15px;
    min-height: 80vh;
    max-width: 300px;
    /* background-color: rgb(225, 225, 226); */
    background-color: #e0e9ff;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	/* align-content: stretch; */
    /* overflow-y: hidden; */
    margin-bottom: 50px;
}

.text-bar {
    /* height: 200px; */
    margin: 0px;
}

.text {
    margin: 0%;
    /* margin-right: 50%; */
    margin-bottom: 0px;
    text-align: left;
    color: #555;
    font-size: small;
}

.register-link {
    margin: auto;
    margin-top: 25px;
    height: 50px;
    width: 100px;
    background-color: #6495ed;
    border-radius: 10%;
}

.agreement-container {
    height: 350px;
}

.agreement-text {
    width: 90vw;
    height: 300px;
    padding: 10px;
    text-align: left;
    color: #555;
    background-color: #fff;
    overflow-y: scroll;
}

.agreement-check {
    display: flex;
    flex-direction: row;
    margin: 20px;
    z-index: 11;
    /* background-color: #fff; */
}