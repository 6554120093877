@keyframes rotating {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

.load-box {
  /* margin: 200px auto; */
  width: 200px;
  height: 200px;
  /* position:absolute; */
  position: fixed;
  /* margin: 25vh 30vh; */
  /* position:100vh; */
  animation: rotating .75s linear infinite;
  /* top: 15vh; */
  left: 40vw;
  /* bottom: 50%; */
  bottom: 25%;
  z-index: 1111;
}
.load-background {
  border-radius:50%;
  border: 10px solid #283c5f;
  border: 10px solid #6495ed;
  width: 200px;
  height: 200px;
}
.load {
  border-radius:50%;
  border-left: 10px solid #6495ed;
  border-top: 10px solid #6495ed;
  border-left: 10px solid #283c5f;
  border-top: 10px solid #283c5f;
  width: 200px;
  height: 200px;
  position: absolute;  
  top: 0;
  left: 0;
}

@media (max-width: 480px) {
  .load-box {
    left: 30vw;
  }
}

@media (max-width: 350px) {
  .load-box {
    left: 20vw;
  }
}

/* @media (max-height:300px) {
  .load-box {
    top: 50vh;
  }
} */