

.connection-container {
    position: relative;
    height:60px;
    width: 330px;
    margin: 2px;
    border-radius: 5px;
    /* border:solid 1px #555; */
    background-color: #fff;
    box-shadow: inset 0 0 1px #000000;
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
	align-content: center;
    /* z-index: 0; */
    /* transform: scaleX(-1); */
    /* transform: scaleX(-1); */
    transition: transform .25s, opacity 0.5s;
}
.button-row {
    display: flex;
    flex-direction: row;
}

/* --- this class will rotate container out of view --- */
/* .connection-container-gone {
    position: relative;
    height:60px;
    width: 300px;
    margin: 2px;
    border-radius: 5px; */
    /* border:solid 1px #555; */
    /* background-color: #fff;
    box-shadow: inset 0 0 1px #000000;
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
	align-content: center; */
    /* z-index: 0; */
    /* transform: scaleX(0); */
    /* transform: scaleX(0); */
/* } */

.photo-name-flex {
    min-width: 100px;
    height: 50px;
    /* background-color: yellow; */
    display: flex;
    /* justify-content: ; */
    align-items: center;
    margin-left: 10px;
}

.connection-name {
    color: #555;
    margin-left: 10px;
    font-size: small;
    font-weight: 600;
}

.connection-photo {
    height: 40px;
    width: 40px;
    border-radius: 10px;
}


/* .friend-options {
    position: absolute;
    height: 90px;
    width: 150px;
    right: 0px;
    top: 50px;
    border-radius: 5px;
    background-color: #6495ed;
    z-index: 1;
    text-align: left;
}

.friend-menu-row {
    height: 40px;
    width: 140px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.friend-menu-icon {
    height: 25px;
    width: 25px;
    color: #fff;
    margin-right: 10px;
}

.friend-menu-text {
    font-size: smaller;
} */

.connection-buttons,
.connection-buttons-full {
    background: linear-gradient(0turn,#5077be,#6495ed);
    color: #fff;
    height: 30px;
    width: 70px;
    border-radius: 2px;
    font-size: smaller;
    border:solid 1px #3c598e;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    margin-left: 2px;
    margin-right: 2px;
    font-weight: 200;
}

.connection-buttons {
    color: #3c598e;
    background: #fff;
    /* font-weight: 600; */
}