
/* .post-box{
    height: 100px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-end;
	align-content: stretch;
    border-bottom: solid 1px #95b3ec;
  } */

  .comment-menu {
      position: absolute;
      height: 40px;
      width: 40px;
      /* margin-left: 50%; */
      right: 0px;
      /* background-color: blue; */
  }

  .container-flex {
      position: relative;
      height: 120px;
      width: 90%;
      margin: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: stretch;
      border-bottom: solid 1px #95b3ec;
      /* background-color: yellow; */
  }

  /* .author-pic {
    height: 50px;
    width: 50px;
    margin-bottom: 50px;
    margin-left: 10px;
    border-radius: 5px;
} */

  .add-button {
      height: 30px;
      width: 50px;
      color: #fff;
      /* background-color: #95b3ec; */
      /* background-color: #3c598e; */
      background: linear-gradient(0turn,#3c598e,#5077be);
  }

  /* .right-column-flex {
    width: 500px;
    height: 100%;
    margin-left: 20px;
    display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: baseline;
    overflow: scroll;
  } */

  .create-post {
    height: 120px;
    width: 100%;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-end;
	align-content: stretch;
    padding-right: 10%;
  }

/* .comment-text {
    height: 85%;
    width: 100%;
    text-align: left;
    color: #555;
    font-size: 18px;
    font-weight: 200;
    letter-spacing: 1px;
    line-height: calc(1ex / 0.32);
    text-shadow:0 0 0 #000, 0 0 1px transparent;
} */

.post-input {
    width: 90%;
    margin-bottom: 10px;
    margin-top: 10px;
    border: solid 1px #6495ed;
    border-radius: 2px;
}

.comment-text-dark {
    font-weight: 600;
    color: #6495ed;
    margin-left: 5px;
    margin-bottom: 0px;
}

@media (max-width:680px) {
    .comment-h5 {
        font-size: x-small;
    }

    .comment-text {
        font-weight: 400;
        font-size: small;
    }

    .author-pic {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        margin-bottom: 80px;
    }
}

