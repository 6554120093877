
.album-container {
    width: 650px;
    height: 800px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* border-radius: 2%; */
    overflow-y:auto;
}

.album-title-row-bottom,
.album-title-row {
    position: relative;
    color: #555;
    
    text-transform: none;
    top: -1px;
    height: 30px;
    width: 100%;
    /* background-color: blue; */
    background-color: #6495ed;
    /* padding-top: 10%; */
    margin: none;
    transition: all 100ms;
}

.album-title-row-bottom {
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: baseline;
	align-content: stretch;
    margin-top: 10px;
}

.photo-selector {
    font-size: 15px;
    font-weight: 600;
    margin-left: 30px;
    margin-top: 8px;
}

.photo-selector:hover {
    border-bottom: solid 2px #3c598e;
}

.photo-selector-selected {
    border-bottom: solid 2px #3c598e;
}

.user-photo-file-input {
    opacity: .01;
    position: absolute;
    background-color: crimson;
    color: #555;
    z-index: 10000;
}

@media (max-width:700px) {
    .album-container {
        max-width: 350px;
        margin: auto;
    }
}