

.player-container {
    position: relative;
    width: 297px;
    height: 350px;
    background-color: #fff;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px -7px #000000;
    display: flex;
    flex-direction: column;
	justify-content: space-between;
	align-items: center;

    overflow: hidden;
}

.video-title {
    color: #fff;
    position: absolute;
    text-transform: none;
    height: 60px;
    width: 298px;
    border-bottom: solid 1px #555;
    transition: all 100ms;
    margin: none;
    display: flex;
    align-items: center;
    align-content: stretch;
}

.video-header {
    position: relative;
    height: 30px;
    width: 280px;
    background: linear-gradient(0turn,#5077be,#6495ed);
    box-shadow: 0px 5px 20px -7px #000000;
    border-radius: 2px;
    margin: auto;
    margin-top: 11px;
    display: flex;
    flex-wrap: wrap;
}

.video-header-text {
    letter-spacing: .5px;
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    margin-left: 2px;
}

.video-user-photo {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    /* margin-right: 5px; */
    margin-left: 10px;
    z-index: 0;
}
  
  .react-player {
    position: relative;
    margin: auto;
    margin-top: 10px;
    box-shadow: 0px 5px 20px -7px #000000;
    border-radius: 5px;
  }

  .video-info-container {
      position: relative;
      height: 70px;
      width: 300px;
      margin: auto;
  }

  .video-container-text {
    position: absolute;
    color: #555;
    font-size: x-small;
    font-weight: 600;
    letter-spacing: .5px;
    text-align: left;
    margin: 10px;
    z-index: 0;
    width: 280px;
    height: 190px;
}

.project-title-text {
    padding-top: 15px;
    padding-bottom: 5px;
    color: #555;
    margin: auto;
    margin-left: 10px;
}

.vidnm-and-usrnm {
    margin-bottom: 5px;
    width: 100%;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: baseline;
	align-content: flex-end;
}

.small-size {
    display: none;
}

@media (max-height:520px) {
    .player-container {
        height: 330px;
    }
}

@media (max-height:500px) {
    .player-container {
        /* height: 320px; */
    }
}

@media (max-width:1000px) {
    .player-container {
        width: 240px;
        margin-left:0px;
        margin-right:20px;
    }
    .video-title {
        width: 241px;
    }
    .video-header {
        width: 230px;
        height: 40px;
    }
    .video-info-container {
        width: 230px;
    }
    .video-container-text {
        font-size: xx-small;
        letter-spacing: .2px;
    }
    .reg-size {
        display: none;
    }
    .small-size {
        display: inline;
        margin: auto;
    }
    .video-nm-txt {
        font-size: 15px;
        font-weight: 400;
    }
}