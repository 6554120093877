
.edit-user-info {

	position: absolute;
	margin-left: auto;
	margin-right: auto;

    height: 270px;

    width: 300px;

	top: 20px;

    border-radius: 2%;
    background-color: #fff;

	box-shadow:10px 5px 60px 10px rgba(36, 36, 36, 1);
	z-index: 10;
	overflow: hidden;
}

.edit-user-info-title {
	color: #fff;
    text-transform: none;
    top: -1px;
	height: 60px;
    width: 100%;
    background: linear-gradient(0turn,#5077be,#6495ed);
    padding-top: 20px;
    margin: none;
	margin-bottom: 2px;
    transition: all 100ms;
}

.user-info-h2 {
    color: #555;
	
}

.input-list {
	/* margin: auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch; */

	border-radius: 2vw;
    margin: auto;
    margin-top: 2vh;
    padding-bottom: 15px;
    /* max-height: 80vh; */
	height: 200px;
    max-width: 300px;
    /* background-color: rgb(225, 225, 226); */
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
}

.list-text {
    color: #555;
	text-align: left;
	font-size: small;
    /* margin-right: 10px; */
}

.li-button {
    margin-right: 0px;;
	width: 50%;
}

/* .photo-properties {
	margin-top: auto;
    height: 50px;
    width: 50px;
	background-color: #fff;
} */

/* .user-photo {
    margin: 20px;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
	z-index: 6;
} */

/* .change-photo {
    margin-left: 20px;
    color: #555;
	z-index: 7;
} */

.edit-photo-line {
	height: 40px;
	width: 290x;
	padding: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: baseline;
	align-content: stretch;
}

.change-pass-container {
	position: absolute;
	height: 200px;
	width: 300px;
	top: 75px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
	/* background-color: yellow; */
	background-color: rgb(240, 240, 240);
    /* box-shadow: 0 0 25px rgba(0, 0, 0, 0.1); */
	box-shadow:10px 5px 60px 10px rgba(36, 36, 36, 1);
	opacity: 1;
	z-index: 1000000000;
}

.change-pass-button-flex {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	/* align-items: center; */
	align-content: flex-start;
}

.submit-row {
	height: 30px;
	width: 100%;
	/* background-color: #5077be; */
}