
/* .create-container {
    height: 450px;
    width: 300px;
    border-radius: 3%;
    background-color: #fff;
    color: #555;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
    align-items: flex-start;
	align-content: center;
    top: 5vh;
    position: fixed;
    z-index:10;
    box-shadow:10px 5px 60px 10px rgba(36, 36, 36, 1);
    margin: none;
    overflow: hidden;
} */

/* .create-title {
    color: #fff;
    position: absolute;
    text-transform: none;
    top: -1px;
    height: 80px;
    width: 100%;
    background: linear-gradient(0turn,#5077be,#6495ed);
    padding-top: 10%;
    margin: none;
    transition: all 100ms;
} */

.upload-div {

    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
}

.log-input {
    height: 33px;
    color: #555;
    text-decoration: overline;
    font-size: 20px;
    /* background-color: blue; */
    z-index: 10000;
}

.disabled { color: gray; }

.log-form-length {
    width: 75%;
}

/* .create-form {
    position: absolute;
    height: 300px;
    width: 100%;
    bottom: 40px;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: flex-start;
    transition: all 100ms;
} */

/* .create-section-title {
    opacity: .5;
    height: 20px;
    width: 100%;
    border-top: solid 1px #6495ed;
    margin-top: 10px;
    text-align: left;
    margin-left: 5px;
    font-size: x-small;
    font-weight: 600
} */

/* .create-upload-div {
    height: 100px;
    background-color: blue;
} */


/* --------- upload section components --------------- */

/* .create-project-row {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	align-content: stretch;
} */

/* .add-file-button {
    background: linear-gradient(0turn,#5077be,#6495ed);
    margin: auto;
    margin-bottom: 50px;
    margin-left: 45px;
    height: 25px;
    width: 200px;
    border-radius: 2px;
    font-size: smaller;
    border:solid 1px #3c598e;
    z-index: 0;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;

} */

/* .add-file-button-text {
    color: #fff;
} */

/* .file-add {
    position: absolute;
    height: 80px;
    width: 100%;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: stretch;
    overflow: none;
    
} */

/* .input-file {
    position: absolute;
    top: 10px;
    height: 40px;
    width: 300px;
    color: #555;
    z-index: 10000;
    background-color: blue;
} */

/* .select-file-1 {
    opacity: .01;
    left: 5px;
    bottom: 5px;
    width: 150%;
    height: 27%;
    z-index: 1;
} */


/* --------- add photo ------------- */

/* .create-input-box {
    position: relative;
    height: 90px;
    width: 100%;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	align-content: center;
    
} */

/* .input[type='file']
{
    text-indent: -999em;
    outline: none;
    position: absolute;
    left: 20%;
    opacity: .1;
    height: 80px;
    width: 80px;
    z-index: 10000;
} */
/* --------------------------------- */

/* -------------- send file ----------- */
.send-file {
    background: linear-gradient(0turn,#5077be,#6495ed);
    height: 25px;
    width: 60px;
    border-radius: 2px;
    font-size: smaller;
    margin-top: 10px;
    border:solid 1px #3c598e
}

.send-file-button {
    color: #fff;
}
/* ------------------------------------- */