.edit-project-container {
    width: 100%;
    min-height: 370px;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: flex-start;
  }

.edit-project-row {
    width: 100%;
    height: 300px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}

.text-input-row {
    height: 150px;
}

.input-left-column {
    height: 100%;
    width: 100%;
    /* width: 500px; */
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    align-items: center;
	align-content: stretch;
}

.section-title {
    opacity: .3;
    height: 20px;
    width: 100%;
    border-top: solid 1px #6495ed;
    margin-top: 10px;
    text-align: left;
    margin-left: 5px;
    font-size: x-small;
    font-weight: 600
}

  .input[type='file']
  {
      text-indent: -999em;
      outline: none;
      position: absolute;
      opacity: .1;
      height: 50px;
  }

.edit-file-input-box,
.edit-photo-input-box {
    position: relative;
    height: 90px;
    width: 200px;
    margin-left: 5px;
    margin-right: 5px;
    top: -30px;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	align-content: center;   
}

.fake-input-file {
    opacity: .01;
    position: absolute;
    top: 20px;
    left: 70px;
    height: 50px;
    width: 200px;
    left: -40px;
    background-color: crimson;
    color: #555;
    z-index: 10000;
}

.edit-file-button {
    position: absolute;
    background: linear-gradient(0turn,#5077be,#6495ed);
    height: 50px;
    width: 200px;
    border-radius: 2px;
    font-size: smaller;
    border:solid 1px #3c598e;
    z-index: 0;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
    color: #fff;
}

.text-input {
    margin-top:10px;
    height: 33px;
    width: 75%;
    color: #555;
    text-decoration: overline;
    font-size: 20px;
    border-radius: 5px;
    text-align: top;
    border: solid 1px #6495ed;
}
.input-description {
    height: 200px;
}

.edit-photo-button {
    position: absolute;
    font-size: small;
    height: 20px;
    width: 20px;
    border:solid 1px red;
    border-radius: 50%;
    /* right: 13%; */
    font-weight: 600;
    color: red;
    background-color: lightcyan;
    opacity: .5;
    margin-top: 5px;
    margin-left: 10px;
}
.replace-file-warning {
    height: 100px;
    width: 100px;
    border-radius: 2px;
    background-color: #fff;
    border: solid 1px #3c598e;
    box-shadow:10px 5px 60px 10px rgba(36, 36, 36, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 1000ms;
    z-index: 1;
}

.select-delete {
    height: 100%;
    width: 100px;
    margin: 0px;
    border: solid 3px #3c598e;
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: flex-start;
	align-content: center;
    color: #555;
}

.delete-button-txt {
    color: #fff;
}

.delete-button-txt:hover {
    color: #3c598e;
}

@media (max-width:700px) {
    .edit-file-button {
        height: 25px;
        width: 80px;
    }

    .edit-photo-input-box {
        height: 25px;
        width: 80px;
    }

    .fake-input-file {
        height: 50px;
        width: 80px;
    }
}

@media (max-width:680px) {
    .text-input {
        width: 100%;
    }
    .input-description {
        height: 100px;
    }
}