.selected-img {
    /* position: absolute; */
    position: fixed;
    left: 25%;
    top: 50px;
    background-color: #fff;
    /* height: 400px; */
    /* width: 400px; */
    min-height: 60px;
    max-height: 400px;
    min-width: 60px;
    max-width: 400px;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
    /* border-radius: 12px; */
    /* box-shadow:10px 5px 60px 10px rgba(36, 36, 36, 1); */
}

.selected-img-head {
    position: relative;
    width: 100%;
    height: 30px;
    /* background-color: #6495ed; */
    background: linear-gradient(0turn,#5077be,#6495ed);
}