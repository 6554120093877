.selected-message-container {
    height: 50px;
    width: 200px;
    background-color: #fff;
    color: #555;
    /* border-radius: 3px; */
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
    /* text-align: center; */
    /* box-shadow: 0 4px 3px 0  rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2); */
    /* box-shadow: inset 0 0 1px #000000; */
    /* border-bottom: solid 1px #555; */
    margin-top: 2px;
    /* border-top: solid 1px #6495ed; */
    /* opacity: .5; */

    /* opacity: 1; */
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);

    transform: scale(1.03);
    z-index: 0;
    /* margin-top: 1px; */
    overflow: hidden;
    margin-bottom: 2px;
    transition: all 100ms;
}
/* .selected-message-container-hide {
    top: 100px;
    min-height: 0px;
    width: 0px;
    transition: all 1000ms;
} */

.selected-message-selected {
/* .selected-message-container:hover { */
    height: 50px;
    width: 200px;
    background-color: #6495ed;
    color: #fff;
    border-radius: 3px;
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
    margin-bottom: 10px;
    margin-top: 10px;
    /* padding-bottom: 5px; */
    -webkit-transform: scale(2.15);
    -ms-transform: scale(2.15);
    transform: scale(1.15);
    
    /* box-shadow: 0 2.5rem 2rem -2rem hsl(200 50% 20% / 40%); */
    /* box-shadow: 0 2.5rem 2rem -2rem hsl(200 50% 20% / 40%); */
    /* box-shadow:10px 5px 60px 10px rgba(36, 36, 36, .5); */

    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
         box-shadow: 0 8px 6px -6px black;

        /* opacity: 0.7; */
    /* -webkit-transition: -webkit-transform 0.5s, opacity 0.5s; */
    /* transition: transform 0.5s, opacity 0.5s; */
    overflow: hidden;
    z-index: 1000;
    transition: all 100ms;
}

.senders-photo {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-left: 5px;
    opacity: 1;
}

.display-name-message {
    /* color: #555; */
    font-size: 15px;
    font-weight: 400;
    margin-left: 20px;
    margin-bottom: 20px;
}

/* .new-message {
    position: absolute;
    color: #e67e22;
    right: 125px;
    top: 25px;
    height: 20px;
    width: 50px;

    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;

    box-shadow: 0 4px 3px 0  rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);

    border-radius: 10px;
    background: rgba(165, 165, 165, 0.116);
    backdrop-filter: blur(12px);
} */

/* @media(max-width:825px) {
    .selected-message-container {
        width: 50px;
    }
} */