


.user-page {
    min-height: 87vh;
    min-width: 300px;
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0)),url(white-backround1.jpg);
    background-size: cover; 
    background-position: center bottom;
    display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	align-content: center;
    padding-top: 5px;
}

.menu {
    list-style: none;
}

.userpage-h3 {
    color: #555;
}

/* -------column1----------- */
.column1 {
    position: relative;
    width: 350px;
    min-height: 70vh;
    float: left;
    overflow-y:auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.name-container {
    height: 45px;
    margin-top: 10px;
    margin-bottom: 0px;
    font-weight: 300;
    color: #555;
    text-transform: none;
}

.background-photo {
    height: 200px;
    width: 99%;
}

.portrait{
    height: 200px;
    width: 99%;
    color: black;
    border-radius: 5px;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    overflow-y: none;
    box-shadow: 0 0 25px rgba(0, 0,  0, 0.1);
    background-color: #fff;
}

.portrait-row {
    position: relative;
    top: -50px;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
}

.profile-photo {
    position: relative;
    top: -50px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border:solid 8px #fff;
    text-align: center;
}

/* .user-buttons {
    background: linear-gradient(0turn,#5077be,#6495ed);
    color: #fff;
    font-size: 16px;
    height: 35px;
    width: 90px;
    border-radius: 2px;
    border:solid 1px #3c598e;
    margin-left: 2px;
    margin-right: 2px;
} */

.go-to-admin {
    position: absolute;
    bottom: -35px;
    font-size: small;
    font-weight: 600;
    color: blue;
}



/* -------column2----------- */
.show-user-small {
    display: none;
}

.column2 {
    border-radius: 5px;
    min-height: 70vh;
    width: 800px;
    float: left;
    overflow-y:auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    /* background-color: #6495ed; */
}

.collections {
    width: 650px;
    height: 800px;
    display: flex;
    flex-direction: row;
	flex-wrap: wrap;
    justify-content: space-around;
    /* border-radius: 2%; */
    overflow-y:auto;
}

/* header.collections.p {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: red;
} */

.input {
    height: 40%;
    width: 90%;
    color: #555;
}

.collections-h2 {
    color: #555;
    padding-bottom: 10px;
}

.items-view {
    width: 90%;
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: baseline;
    align-content: stretch;
    overflow-y:auto;
}

/* ----props display info---- */
.props-display {
    height: 188;
    width: 200px;
    background-color: #fff;
    color: #555;
    margin: 5px;
    box-shadow: 0 2.5rem 2rem -2rem hsl(200 50% 20% / 40%);
}

.project-text {
    color: #555;
}

.project-box-h4 {
    color: #555;
}

.model-img {
    width: 100%;
    height: 90%;
    filter: brightness(80%);
}

.text-element {
    margin-right: 15px;
    margin-bottom: 5px;
    font-weight: 100;
    text-transform: none;
    font-size: small;
}

.title {
    margin-left: 12px;
    font-weight: 300;
    display:flex;
}

.icon-big {
    margin-top: 30%;
    height: 10vh;
    color:  #6495ed;
}

.icon-medium {
    padding-right: 100%;
    height: 5vh;
}

@media (max-width: 1075px) {
    .column2 {
        width: 350px;
        overflow: auto;
    }
}

@media (max-width: 700px) {
    .column2 {
        top: 500px;
        width: 400px;
    }

    .column1 {
        margin-top: 10px;
    }
}