
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: black; */
  /* width: 80vw; */
  /* width: 60vw; */
  /* margin-left: auto;
  margin-right: auto;
  padding-top: 100px; */
}

html {
  background-color: #fff;
  color: #fff;
  font-family: 'Lato', 'Arial',sans-serif;
  font-weight: 200;
  font-size: 20px;
  line-height: 1.50;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}

.long-text {
  font-size: 15px;
  line-height: 1.5;
  font-family: 'Inter';
}

.clearfix {zoom: 1;}
.clearfix:after {
    content: '.';
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}


.App {
  text-align: center;
  max-height: 100vh;
  min-width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h3,
h2,
h1 {    
    font-weight: 400;
}
h1 {
    margin: 0;
    padding-top: 30vh;
    padding-right: 50vh;
    color: white;
    font-size: 240%;
    word-spacing: 4px;
    letter-spacing: 1px;
}

h4 {
  font-weight: 200;
  color: #555;
}

button,
.user-buttons {
  background: linear-gradient(0turn,#5077be,#6495ed);
  color: #fff;
  font-size: 16px;
  height: 35px;
  width: 90px;
  border-radius: 2px;
  border:solid 1px #3c598e;
  margin-left: 2px;
  margin-right: 2px;
  transition: all 500ms;
}

button:hover,
.user-buttons:hover {
  background: linear-gradient(0turn,#3c598e,#5077be);
}

.link-button {
  margin-right: 5vw;
  height: 30px;
  width: 100px;
  background: linear-gradient(0turn,#3c598e,#5077be);
  color: #fff;
  box-shadow: 0px 5px 8px -17px #000000;
  text-transform: none;
}
.link-button:hover {
  background: linear-gradient(0turn,#5077be,#6495ed);
  border:solid 1px #3c598e;
  transition: all 500ms;
}

.content-box {
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 2.5rem 2rem -2rem hsl(200 50% 20% / 40%);
  padding: 20px;
}

/* ----- css for Document.js component ----- */

.thumbnail-image {
  height: 50px;
  width: 60px;
  border-radius: 5%;
}

.about-h4 {
  float: left;
  margin: 10px;
  margin-left: 5px;
  font-weight: 200;
}

.h4-border {
  margin: 10px;
  width: 50px;
  border-bottom: 2px solid #e67e22;
}

::-webkit-scrollbar {
  width: 20px;
}

.doc-containers {
  padding: 10px;
  padding-left: 25px;
  margin: 15px;
  min-height: 30px;
  width: 400px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px #d1d1d1;
  color: #555;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.sub-header  {
  position: absolute;
  margin-top: 2px;
  height: 30px;
  width: 100vw;
  background: linear-gradient(0turn,#5077be,#6495ed);
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}

.selected {
  color: #fff;
  background-color: #3c598e;
  border-radius: 2px;
}

@media (max-width: 700px) {
  .doc-containers {
    width: 300px;
  }
}

.white-background {
  width: 300px;
  background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0)),url(white-backround1.jpg);
  background-size: cover; 
  background-position: center bottom;
  min-height: 90vh;
}

.dark-text {
  padding-top: 15px;
  padding-bottom: 5px;
  color: #555;
  margin-left: 10px;
}

i {
  margin-left: 10px;
  margin-bottom: 2px;
  color: #6495ed;
  font-size: xx-small;
  font-weight: 600;
}

/* ---- This section is for global menu styling ---- */
.friend-options {
  position: absolute;
  height: 90px;
  width: 150px;
  right: 0px;
  top: 50px;
  border-radius: 5px;
  background-color: #6495ed;
  z-index: 1;
  text-align: left;
}

.friend-menu-row {
  height: 40px;
  width: 140px;
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.friend-menu-icon {
  height: 25px;
  width: 25px;
  color: #fff;
  margin-right: 10px;
}

.friend-menu-text {
  font-size: smaller;
}
/* -------------------------------- */

@media (max-height:375px) {
    .dark-text {
      margin: none;
      font-size: large;
      font-weight: 400;
  }
}

@media (max-width:300px) {
  .sub-header {
    min-width: 300px;
  }
}