
.game-title {
    color: #fff;
    position: absolute;
    text-transform: none;
    top: -1px;
    height: 40px;
    /* width: 355px; */
    /* width: 300px; */
    width: 100%;
    background: linear-gradient(0turn,#5077be,#6495ed);
    padding-top: 10%;
    margin: none;
    transition: all 100ms;
}

.foe-photo {
    position: absolute;
    margin-top: 25px;
    /* margin-bottom: 0px; */
    height: 40px;
    width: 40px;
    border-radius: 50%;
    z-index: 10000;
}

@media(max-width:690px) {
    .game-container{
        left: 30px;
    }
}

@media (max-width:430px) {
    .game-container {
        width: 350px;
        left: 20px;
    }
}

@media (max-width:370px) {
    .game-container {
        left: 10px;
    }
}

@media (max-width:350px) {
    .game-container {
        width: 300px;
    }
}

@media (max-width:300px) {
    .game-container {
        width: 280px;
    }
}