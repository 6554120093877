.admin-container {
    min-height: 100vh;
    min-width: 90hw;
    background-color: rgb(225, 225, 226);
    margin: auto;
    display: flex;
	flex-direction: column;
    flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
    overflow:auto;
}

.add-doc-div {
    position: relative; 
    height: 400px;
    width: 400px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
    background-color: white;
    border-radius: 2%;
    /* box-shadow: 2px 2px#d1d1d1; */
    z-index: 0;
    overflow: scroll;
}

.add-doc-div-closed {
    height: 20px;
    width: 200px;
    color: #555;
}

div.add-doc-div-closed a {
    font-size: small;
}

input[type="text"]
{
    background: transparent;
    height: 100vh;
    width: 300px;
    border-radius: 5%;
    overflow-x: none;
    word-wrap: break-word;
    word-break: break-all;

}

.checkbox {
    display: flex;
    flex-direction: row;
    font-size: x-small;
    margin-bottom: 10px;
}

.admin-header-ul {
    height: 30px;
    min-width: 90vw;
    background-color: rgb(243, 243, 250);
    /* box-shadow: 2px 2px#d1d1d1; */
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: large;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: baseline;
	align-content: stretch;
}

ul.admin-header-ul li a {
    color: #555;
    text-transform: uppercase;
}

.admin-a {
    color: #555;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
	align-content: stretch;
}

.show-user {
    margin: 10px;
    color: #555;
}

.display-traffic {
    min-height: 100%;
    min-width: 600px;
    background-color: #fff;
    
}

.traffic-text {
    color: #555;
    margin: auto;
}

.traffic-display-unit {
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
}