.user-info {
    margin-top: 10px;
    height: 400px;
    height: 180px;
    /* width: 100%; */
    width: 350px;
    /* width: 99%; */
    /* background-color: rgb(248, 244, 244); */
    /* background-color: chartreuse; */
    /* margin-top: 400px; */
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	/* justify-content: center; */
    justify-content: start;
	align-items: stretch;
    align-content: center;
    overflow-y:auto;
    /* padding-top: 10%; */
    /* padding-bottom: 10%; */
    border-radius: 2px;           
}

.user-info-p {
    text-align: left;
    margin-left: 15px;
    margin-top: 5px;
    font-size: small;
}

.user-info-h2 {
    /* margin-left: 15%; */
    /* margin-right: 15%; */
    margin-top: 5px;
    text-align: right;
    color: #555;
    width: 350px;
    /* width: 300px; */
    background-color: rgb(253, 249, 249);
    /* background-color: chartreuse; */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.user-title-h {
    text-align: center;
    font-weight: 300;
    text-transform: none;
}

/* .edit-user-info-button {
    margin: auto;
    margin-top: 10px;
    margin-bottom: none;
    padding: none;
} */

.user-info-title {
    background-color: #6495ed;
    opacity: .8;
    height: 24px;
    width: 350px;
    display: flex;
}

.user-info-title-text {
    margin-left: 5px;
    font-size: medium;
    color: #3c598e;
    text-transform: none;
    font-weight: 600;
}
